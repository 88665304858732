<template>
  <div class="container">
    <h1>마이페이지</h1>
    <div class="mt-4" style="display: flex;flex-flow: row;gap:20px;" v-if="user">
      <div style="width:60%">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h2 style="margin-top:0">기본정보</h2>

              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이메일" v-model="user.email" readonly />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이름" v-model="user.username" readonly />
              </b-input-group>

              <b-input-group class="mb-3" v-if="user.apiKey">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-key"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="API Key" v-model="user.apiKey" readonly />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="기존 비밀번호" v-model="user.password" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="새 비밀번호" v-model="user.newPassword" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="새 비밀번호 확인"
                  v-model="user.newPasswordConfirm" />
              </b-input-group>
              <b-button @click.prevent="$router.go(-1)" variant="secondary" block>이전</b-button>
              <b-button @click.prevent="updateUser" variant="primary" block>수정</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
      <div style="width:100%;overflow: hidden;">
        <MyShortenList :user="user" v-if="user" />
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import MyShortenList from '@/views/components/MyShortenList.vue';
import AuthService from '@/services/AuthService'

export default {
  name: 'User',
  components: {
    MyShortenList
  },
  created() { },
  mounted() {
    document.body.classList.remove('bg-white')
    this.getUser()
  },
  data() {
    return {
      isLoading: false,
      user: null,
      newPassword: '',
      newPasswordConfirm: ''
    }
  },
  watch: { user() { }, 'user.email': {}, 'user.username': {}, isLoading() { } },
  methods: {
    async getUser() {
      this.isLoading = true
      const response = await AuthService.getUser()
      const { user } = response.data
      this.user = user
      this.isLoading = false
    },
    async updateUser() {
      if (this.newPassword) {
        if (this.newPassword != this.newPasswordConfirm) {
          alert('비밀번호가 일치하지 않습니다')
          return
        }
      }
      const response = await UserService.updateUser(this.user.id, {
        username: this.user.username,
        password: this.user.password,
        newPassword: this.user.newPassword,
      })
      if (response.status == 200) {
        alert('수정 완료')
        this.user.password = null
        this.user.newPassword = null
        this.user.newPasswordConfirm = null
      }
    },
  },
}
</script>
