<template>
  <b-card no-body class="p-0 m-0">
    <b-card-body class="p-4">
      <h2 style="margin-top:0">내가 만든 링크 관리</h2>
      <table class="mt-4 border-table" v-show="links.length > 0">
        <thead>
          <tr>
            <td style="width:140px">링크종류/생성시각</td>
            <td>링크</td>
            <td style="width:160px"></td>
            <td class="text-center" style="min-width:100px">클릭수</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in links" :key="i">
            <td class="text-center" style="vertical-align: top;">
              <span class="type-label">{{ renderTypeName(item.type) }}</span>
              <br />
              <span class="date-format">{{ item.createdAt | dateFormatWithNewLine }}</span>
            </td>
            <td style="vertical-align: top;">
              <button class="hover-btn">
                <img src="/img/i_copy.svg" @click="copyToClipboard(getShortenUrl(item))" class="i-copy"
                  v-b-tooltip.hover title="복사하기">
              </button>
              <span class="link-url" @click="goToShortenUrl(item)">{{ getShortenUrl(item) }}</span>
              <br />
              <span class="raw-data" v-show="!item.items || [3, 4].includes(item.type)">{{ item.targetUrl
                }}</span>
              <div v-show="item.items && item.items.length > 0" class="img-list-wrap">
                <div class="img-wrap" v-for="(imgData, index) in item.items" :key="index">
                  <img :src="imgData['data']" alt="" />
                </div>
              </div>
            </td>
            <td class="text-right" style="vertical-align: bottom;position: relative;">
              <span @click="pushUserInfo(item.user)" style="position:absolute;top:20px;right:0;cursor: pointer;"
                v-show="user.level == 9">
                {{ item.user && item.user.username }}
              </span>
              <div style="color: #999;font-size: 13px;">위 링크는 <span class="color-danger">
                  {{ getDDay(item.createdAt) }}</span>일 뒤에<br />자동으로 삭제됩니다
              </div>
            </td>
            <td class="text-center" style="position: relative;vertical-align: top;">
              <p class="block" style="min-height:100px;">
                <span style="font-size: 24px;font-weight:bold;white-space: nowrap;">
                  {{ item.viewCount | numberFormat }}
                </span>
              </p>
              <button class="btn btn-danger btn-sm block" @click="deleteItem(item)"
                style="position: absolute;bottom:15px;left:50%;transform:translate(-50%);width:80px;white-space: nowrap;">
                지금 삭제
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination v-show="!isLoading && totalCount > 10" align="center" :total-rows="totalCount" v-model="page"
        :per-page="10" :limit="10" class="mt-4 " @change="changePage"></b-pagination>

      <div class="mt-4 empty-view" v-show="!isLoading && totalCount == 0">
        아직 내가 만든 링크가 없습니다.<br />
        링크는 발송페이지에서 만드실 수 있습니다.
      </div>
      <PulseLoader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size" style="margin:50px 0" />
    </b-card-body>
  </b-card>
</template>
<script>
import ShortenUrlService from '@/services/ShortenUrlService'
import moment from 'moment';
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

export default {
  mounted() {
    console.log(this.user);
    this.fetchList(1);
  },
  props: {
    user: {},
  },
  components: {
    PulseLoader,
  },
  methods: {
    async fetchList(page) {
      try {
        this.page = page
        this.isLoading = true
        const response = await ShortenUrlService.fetchList(page)
        const { list = [], totalCount = 0 } = response.data
        this.links = list
        this.totalCount = totalCount
      }
      catch (e) {
        // do nothing
      } finally {
        this.isLoading = false
      }

    },
    renderTypeName(type) {
      if (type === 3) {
        return '짧은 도메인'
      }
      else if (type === 4) {
        return '한글 도메인'
      }
      else {
        return '이미지 링크'
      }
    },
    getShortenUrl(item) {
      if (item.type === 3) {
        return 'zal.vn/' + item.shortId
      }
      else if (item.type === 4) {
        return item.shortId + '.클릭.cc'
      }
      else {
        return '이미지보기.tv/' + item.shortId
      }
    },
    changePage(page) {
      this.fetchList(page);
    },
    getDDay(createdAt) {
      // D-Day (createdAt로부터 60일 후)
      const dDay = moment(createdAt).add(60, 'days');

      // 오늘 날짜
      const today = moment();

      // D-Day까지 남은 일수 계산
      const daysUntilDDay = dDay.diff(today, 'days');
      return daysUntilDDay;

    },
    async deleteItem(item) {
      await ShortenUrlService.delete(item.id)
      this.fetchList();
    },
    goToShortenUrl(item) {
      window.open('https://' + this.getShortenUrl(item))
    },
    copyToClipboard(text) {
      var textArea = document.createElement("textarea");

      // 복사할 텍스트를 textarea에 설정
      textArea.value = text;

      // body에 textarea 추가
      document.body.appendChild(textArea);

      // textarea를 선택하고 복사 실행
      textArea.select();
      document.execCommand("copy");

      // body에서 textarea 제거
      document.body.removeChild(textArea);
    },
    pushUserInfo(user) {
      this.$router.push('/admin/users/' + user.id)
    }
  },
  data() {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      links: [],
      page: 1,
      totalCount: 0
    }
  },
  watch: {
    links() { },
  },
}
</script>
<style scoped lang="scss">
.type-label {
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #333;
  color: #333;
}

.date-format {
  white-space: pre;
}

.link-url {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.raw-data {
  margin-top: 5px;
  margin-left: 30px;
  font-size: 13px;
  color: #999;
}


.img-list-wrap {
  margin-left: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
}

.img-wrap {
  margin-top: 5px;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1.34;
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.is-admin .img-wrap img {
  // filter: blur(3px)
}

.color-danger {
  color: var(--color-danger);
}

.i-copy {
  width: 15px;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

.empty-view {
  background-color: var(--color-flat-white);
  color: #333;
  text-align: center;
  padding: 50px 20px;
}
</style>